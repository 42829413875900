import React from "react";
import About from "../components/About/About";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";

const AboutPage = () => {
  return (
    <>
      <Navbar />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <About />
      <Footer />
    </>
  );
};

export default AboutPage;
