import React from "react";
import Footer from "../components/footer/Footer";
import Contact from "../components/contact/Contact";
import Navbar from "../components/navbar/Navbar";

const ContactPage = () => {
  return (
    <>
      <Navbar />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Contact />
      <Footer />
    </>
  );
};

export default ContactPage;
