import React from "react";
import { Link } from "react-router-dom";
import "./AboutStyles.css";
import logo from "../../assets/logo12.png";

const About = () => {
  return (
    <div className="about">
      <div className="contaniner">
        <div className="content">
          <div className="boxtile">
            <h1 className="h1">
              <span>Why Us</span>
            </h1>
          </div>
          <h3>Overview</h3>
          <div className="box">
            <p>
              Maintaining security of data and information has become extremely
              important. As with material assets, companies must ensure that
              necessary safeguards are in place to protect their data and
              information from misuse. The base of security is the base of
              securing your data.<br></br>
              <br></br> Our expertise in the SAP Security space is there to help
              you in the area of governance and proper controls for your SAP
              environment. Whether your organization is running older
              installations of SAP ERP or running the latest S/4HANA solution,
              SILOTU Consulting services are ready to help your business manage
              and run its SAP application to give you a peace of mind.<br></br>
              <br></br> With a combined experience of over 20 years in managing
              SAP Security and Access Controls across various industries, SILOTU
              Consulting will help you build, optimize and run your
              organization's SAP Access Controls hassle-free.
            </p>
          </div>
          <br></br>
          <div className="boxlogo">
            <img src={logo} alt="" />
          </div>

          <div className="btn">
            <Link to="/contact">
              <button className="button1">Get in touch</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
