import React from "react";
import Hero from "./components/hero/Hero";
import Navbar from "./components/navbar/Navbar";
import Services from "./components/Services/Services";
import About from "./components/About/About";
// import Data from "./components/Data/Data";
// import Contact from "./components/contact/Contact";
import Partners from "./components/Partners/Partners";
import BlogPost from "./components/BlogPost/BlogPost";
import Footer from "./components/footer/Footer";
// import CreatePost from "./components/CreatePost/CreatePost";
// import Information from "./components/Information/Information";

function App() {
  return (
    <>
      <Navbar />
      <Hero />
      <Services />
      <About />
      <Partners />
      <BlogPost />
      {/* <Data /> */}
      {/* <Information /> */}
      {/* <Contact /> */}
      {/* <CreatePost /> */}
      <Footer />
    </>
  );
}

export default App;
