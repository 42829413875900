import React from "react";
import { Link } from "react-router-dom";
import "./HeroStyles.css";
// import MainVideo from "../../assets/Video.mp4";

const Hero = () => {
  return (
    <div className="hero">
      {/* <video autoPlay loop muted id="video">
        <source src={MainVideo} type="video/mp4" />
      </video> */}
      <div className="container">
        <div className="content">
          <h1>SILOTU Consulting</h1>
          <h1 className="blue">SAP Security Excellence</h1>
          <p>
            Providing Affordable SAP Security Support and GRC Consulting
            Services
          </p>
          <br></br>
          <div className="btn5">
            <Link to="/data">
              <button className="button1">Get Started</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
