import React from "react";
import "./BlogPost.css";
import blogimg1 from "../../assets/tech1.jpg";
import blogimg2 from "../../assets/tech2.jpg";
import blogimg3 from "../../assets/tech3.jpg";
import { Link } from "react-router-dom";
import AllPosts from "../AllPost/AllPost";

const BlogPost = () => {
  return (
    <div className="blogpost">
      <div className="content">
        <div className="boxtile">
          <h1 className="h1">
            <span>Our Blog</span>
          </h1>
        </div>
        <br></br>
        <h3 className="blogh3">
          What is SILOTU Consulting Services? The Complete Beginner's Guide
        </h3>
        <div className="blogtag">
          <p className="blogptag">
            Maintaining the security of data and information is essential for
            businesses of all sizes. Without proper safeguards in place,
            companies risk exposure to misuse and other threats. SILOTU
            Consulting Services is a company that provides a range of services
            designed to help organizations protect their data and information,
            optimize their use of SAP software, and improve efficiency and
            effectiveness. In this guide, you'll find everything you need to
            know to get started with SILOTU Consulting Services.
          </p>
          <br></br>
          <div className="blog-grid-box">
            <div className="blogcard">
              <h3 className="headblog">
                What is SILOTU Consulting <br></br>Services?
              </h3>
              <div className="blogtextarea">
                <p className="cardtext">
                  SILOTU Consulting Services is a company that provides a range
                  of services related to the SAP software platform. These
                  services include SAP HANA database security, SAP ITGC
                  auditing, SAP optimization, SAP licensing, SAP projects and
                  implementations, SAP support, SAP PO support, SAP GRC and IdM
                  support, SAP marketplace administration, and SAP printer
                  administration.
                  <br></br>
                </p>
                <div className="blogimg">
                  <img className="bloginimg" src={blogimg1} alt="" />
                </div>
              </div>
            </div>
            <div className="blogcard">
              <h3 className="headblog">
                Why is SILOTU Consulting Services<br></br> important?
              </h3>
              <div className="blogtextarea">
                <p className="cardtext">
                  There are several reasons why SILOTU Consulting Services is
                  valuable to businesses:
                </p>
                <p className="cardtext">
                  <br></br>• It helps organizations maintain the security of
                  their data and information
                  <br></br>• It ensures proper governance and controls in their
                  SAP environment
                  <br></br>• It helps businesses optimize their use of SAP
                  software to improve efficiency and effectiveness
                </p>
                <div className="blogimg">
                  <img className="bloginimg" src={blogimg2} alt="" />
                </div>
              </div>
            </div>
            <div className="blogcard">
              <h3 className="headblog">
                What is SILOTU Consulting <br></br>Services?
              </h3>
              <div className="blogtextarea">
                <p className="cardtext">
                  SILOTU Consulting Services is a company that provides a range
                  of services related to the SAP software platform. These
                  services include SAP HANA database security, SAP ITGC
                  auditing, SAP optimization, SAP licensing, SAP projects and
                  implementations, SAP support, SAP PO support, SAP GRC and IdM
                  support, SAP marketplace administration, and SAP printer
                  administration.
                </p>
                <div className="blogimg">
                  <img className="bloginimg" src={blogimg3} alt="" />
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
          <div className="longcard">
            <div className="topdiv">
              <h2 className="longcard-h2">
                Types of SILOTU Consulting Services
              </h2>
            </div>
            <div className="grid-L-R">
              <div className="leftdiv">
                <h2 className="leftdivh2">
                  SILOTU Consulting Services offers a range of services
                  <br></br>
                  related to the SAP software platform.
                </h2>
              </div>
              <div className="rightdiv">
                <div className="iconbtn">
                  <Link to="/information">
                    <button className="buttonlongcard">Read more</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="eventsblog">
          <h3 className="eventhead">Events</h3>
          <br></br>
          <div className="boxtile">
            <h1 className="h1">
              <span>All Post</span>
            </h1>
          </div>
          <h3>Welcome to SILOTU Consuting posts update page!</h3>
          <br></br>
          {/* This is a Blog post live update area */}
          <div className="all-post">
            <AllPosts />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
