import React from "react";
import Data from "../components/Data/Data";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";

const DataPage = () => {
  return (
    <>
      <Navbar />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Data />
      <Footer />
    </>
  );
};

export default DataPage;
