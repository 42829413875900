import { useState } from "react";
import "./NavbarStyles.css";
import logo from "../../assets/logoaW.png";
import { HiMenuAlt3 } from "react-icons/hi";
import { RiCloseFill } from "react-icons/ri";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleNav = () => setNav(!nav);
  return (
    <div className="navbar top">
      <div className="container">
        <div className="logo">
          <a href="/">
            <img src={logo} alt="" />
          </a>
        </div>

        <ul className={nav ? "nav-menu active" : "nav-menu"}>
          <li>
            <Link className="navtext" to="/">
              Home
            </Link>
          </li>
          <li>
            <Link className="navtext" to="/about">
              About
            </Link>
          </li>
          <li>
            <Link className="navtext" to="/services">
              Services
            </Link>
          </li>
          {/* <li>
            <Link className="navtext" to="/data">
            Data
            </Link>
          </li> */}
          <li>
            <Link className="navtext" to="/partners">
              Partners
            </Link>
          </li>
          <li>
            <Link className="navtext" to="/blogpost">
              Blog
            </Link>
          </li>
          <li>
            <Link to="/contact">
              <button className="contactbtn">Contact</button>
            </Link>
          </li>
        </ul>
        <div className="hamburger" onClick={() => handleNav()}>
          {!nav ? (
            <HiMenuAlt3 className="icon" />
          ) : (
            <RiCloseFill className="icon" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
