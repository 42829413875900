import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import ServicesPage from "./pages/ServicesPage";
import AboutPage from "./pages/AboutPage";
import DataPage from "./pages/DataPage";
import ContactPage from "./pages/ContactPage";
import PartnersPage from "./pages/PartnersPage";
import InformationPage from "./pages/InformationPage";
import BlogPost from "./pages/BlogPostPage";
import OnePost from "./components/OnePost/OnePost";
// import LoginPage from "./pages/LoginPage";

// const [isAuth, setIsAuth] = useState(false);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/services" element={<ServicesPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/data" element={<DataPage />} />
      <Route path="/blogpost" element={<BlogPost />} />
      {/* ALTER */}
      {/* <Route element={<AllPosts />} path="/all" /> */}
      <Route element={<OnePost />} path="/:slug" />
      {/*  */}
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/partners" element={<PartnersPage />} />
      <Route path="/information" element={<InformationPage />} />
    </Routes>
  </BrowserRouter>
);
