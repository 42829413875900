import React from "react";
import "./InformationStyles.css";
import icon1 from "../../assets/icon1.png";
import icon2 from "../../assets/icon2.png";
import icon3 from "../../assets/icon3.png";
import icon4 from "../../assets/icon4.png";
import icon5 from "../../assets/icon5.png";
import icon6 from "../../assets/icon6.png";
import icon7 from "../../assets/icon7.png";
import icon8 from "../../assets/icon8.png";
import icon9 from "../../assets/icon9.png";
import icon10 from "../../assets/icon10.png";
import icon11 from "../../assets/icon11.png";

const Information = () => {
  return (
    <div className="information">
      <div className="contaniner">
        <div className="contennt">
          <div className="boxtile">
            <h1 className="h1">
              <span>Our Services Information</span>{" "}
            </h1>
          </div>

          <p className="p-slt">
            SILOTU Consulting is offering below services at cost effective rates
            to address the protection of data and <br></br>information on your
            organizations SAP Enterprise Software.
          </p>
          <h2 className="h2e">Executive Summary</h2>
          <p className="infortext-head">
            Below is an executive summary of our service offerings:
          </p>
        </div>
        <br></br>
        <div className="inforgrid-container">
          <div className="grid-itemlong">
            <h3 className="h3infor">
              SAP HANA <br></br>Database Security
            </h3>
            <div className="tilebox">
              <img className="iconI" src={icon1} alt="" />
            </div>
            <div className="textbox">
              <p
                className="infortext"
                style={{
                  maxHeight: "250px",
                  overflowY: "scroll",
                  scrollbarWidth: "thin",
                  scrollbarColor: "linear-gradient(to right, #00b0ff, #00f5ff)",
                  scrollbarRadius: "50px",
                  scrollBehavior: "smooth",
                }}
              >
                Protecting your data is important to you, and so it is to us.
                Cyber-crimes are becoming more sophisticated by the day and
                keeping your system secure while staying on top of the
                compliance and regulatory requirements of today’s digital world
                is vital. SAP HANA allows you to securely run and operate SAP
                HANA in a variety of environments and to implement your specific
                compliance, security, and regulatory requirements. We can help.
                Using our specialized skills in this area allows us to offer
                your organization the best possible data security by offering
                these services:<br></br>
                <br></br>• Model analytical authorizations
                <br></br>• Create Security policies to monitor user activity
                <br></br>• Design design-time roles to cater for your
                organization’s strategy
                <br></br>• User provisioning and support
                <br></br>• Security auditing and password complexity maintenance
                <br></br>• Creation and replication of analysis authorizations
                on BW/4HANA
                <br></br>• Model design-time authorization roles for your CDS
                and Calculation views
              </p>
            </div>
          </div>
          <div className="grid-itemlong">
            <h3 className="h3infor">
              SAP ITGC <br></br>Auditing Services
            </h3>
            <div className="tilebox">
              <img className="iconI" src={icon2} alt="" />
            </div>
            <div className="textbox">
              <p
                className="infortext"
                style={{
                  maxHeight: "250px",
                  overflowY: "scroll",
                  scrollbarWidth: "thin",
                  scrollbarColor: "linear-gradient(to right, #00b0ff, #00f5ff)",
                  scrollbarRadius: "50px",
                  scrollBehavior: "smooth",
                }}
              >
                We provide end-to-end audit services to ensure you are compliant
                and external audit ready. Our audit service includes:<br></br>
                <br></br>• Periodic Basis audit reviews
                <br></br>• Segregation of Duties (SODs) audit
                <br></br>• SAP HANA Database and SAP PO audit reviews
                <br></br>• Periodic GRC reporting
                <br></br>• Post project implementation audit reviews
              </p>
            </div>
          </div>
          <div className="grid-itemlong">
            <h3 className="h3infor">
              SAP Optimization<br></br>Services
            </h3>
            <div className="tilebox">
              <img className="iconI" src={icon3} alt="" />
            </div>
            <div className="textbox">
              <p
                className="infortext"
                style={{
                  maxHeight: "250px",
                  overflowY: "scroll",
                  scrollbarWidth: "thin",
                  scrollbarColor: "linear-gradient(to right, #00b0ff, #00f5ff)",
                  scrollbarRadius: "50px",
                  scrollBehavior: "smooth",
                }}
              >
                Our SAP optimization service provides checks and balances that
                ensure your SAP system is actively monitored for security
                threats before they become a problem. Our optimization service
                includes:<br></br>
                <br></br>• Early Watch Reports
                <br></br>• Security optimization through periodic background
                jobs
                <br></br>• Reviewing and implementing security notes
                <br></br>• Configure audit logging and monitoring
                <br></br>• Configure SODs reports using SAP standard reporting
                tool
                <br></br>• Configure Security Optimization Services (SOS) by
                leverage your existing Solution Manager application
              </p>
            </div>
          </div>
          <div className="grid-itemlong">
            <h3 className="h3infor">
              SAP Licensing<b></b> Services
            </h3>
            <div className="tilebox">
              <img className="iconI" src={icon4} alt="" />
            </div>
            <div className="textbox">
              <p className="infortext">
                Reduce license costs by using our service to ensure you only pay
                for the right license. At SILOTU Consulting our activity-based
                model ensures the correct license is given to the correct user.
                We believe in classifying your users correctly first-time round
                to reduce future maintenance efforts.
              </p>
            </div>
          </div>
          <div className="grid-itemlong">
            <h3 className="h3infor">
              SAP Projects &<br></br> Implementations
            </h3>
            <div className="tilebox">
              <img className="iconI" src={icon5} alt="" />
            </div>
            <div className="textbox">
              <p
                className="infortext"
                style={{
                  maxHeight: "250px",
                  overflowY: "scroll",
                  scrollbarWidth: "thin",
                  scrollbarColor: "linear-gradient(to right, #00b0ff, #00f5ff)",
                  scrollbarRadius: "50px",
                  scrollBehavior: "smooth",
                }}
              >
                We are committed to providing your organization an efficient,
                cost-effective, scalable authorizations strategy. We’ll Analyse
                your business requirements, develop a Strategy to tackle your
                individual business needs, Design a scalable authorization
                concept, Build the authorization concept that suites your needs,
                ensure that your concept Runs successfully, and finally Support
                you. Our P&I Service includes:<br></br>
                <br></br>• Our Analyse, Strategize, Design, Build, Run, Support
                methodology
                <br></br>• Role rewrites
                <br></br>• Implement a new SAP Security/Authorizations Concept
                <br></br>• Implement an SAP HANA Security strategy
              </p>
            </div>
          </div>
          <div className="grid-itemlong">
            <h3 className="h3infor">
              SAP Support<br></br> Services
            </h3>
            <div className="tilebox">
              <img className="iconI" src={icon6} alt="" />
            </div>
            <div className="textbox">
              <p
                className="infortext"
                style={{
                  maxHeight: "250px",
                  overflowY: "scroll",
                  scrollbarWidth: "thin",
                  scrollbarColor: "linear-gradient(to right, #00b0ff, #00f5ff)",
                  scrollbarRadius: "50px",
                  scrollBehavior: "smooth",
                }}
              >
                Depending on your solution, our support service provides
                continuous support to your business’ SAP Enterprise software by
                protecting your valuable data from unauthorized access. We’ll
                ensure only the right user is accessing the right data at the
                right time. Data is important to you, and so we value what is
                important to you. Our support services include the below SAP
                applications:<br></br>
                <br></br>• S/4HANA
                <br></br>• ERP
                <br></br>• HR
                <br></br>• BW & BW4/HANA
                <br></br>• CRM
                <br></br>• BOBJ
                <br></br>• CAR
                <br></br>• PO
                <br></br>• FIORI
                <br></br>• SOLMAN
                <br></br>• SAP Analytics Cloud (SAC)
              </p>
            </div>
          </div>
          <div className="grid-itemlong">
            <h3 className="h3infor">
              SAP PO Support<br></br> Services
            </h3>
            <div className="tilebox">
              <img className="iconI" src={icon7} alt="" />
            </div>
            <div className="textbox">
              <p
                className="infortext"
                style={{
                  maxHeight: "250px",
                  overflowY: "scroll",
                  scrollbarWidth: "thin",
                  scrollbarColor: "linear-gradient(to right, #00b0ff, #00f5ff)",
                  scrollbarRadius: "50px",
                  scrollBehavior: "smooth",
                }}
              >
                Often a neglected part of the SAP suite when it comes to a
                proper authorization methodology. We believe in the importance
                of data protection for your organization that is why we made it
                our mission to specialize in securing and managing your JAVA
                application server. Thus, our PO service offerings include:
                <br></br>
                <br></br>• User and role administration
                <br></br>• Develop scalable action-based roles
                <br></br>• Manage super users
                <br></br>• Password policy maintenance
              </p>
            </div>
          </div>
          <div className="grid-itemlong">
            <h3 className="h3infor">
              SAP GRC & IDM<br></br> Support Services
            </h3>
            <div className="tilebox">
              <img className="iconI" src={icon8} alt="" />
            </div>
            <div className="textbox">
              <p
                className="infortext"
                style={{
                  maxHeight: "250px",
                  overflowY: "scroll",
                  scrollbarWidth: "thin",
                  scrollbarColor: "linear-gradient(to right, #00b0ff, #00f5ff)",
                  scrollbarRadius: "50px",
                  scrollBehavior: "smooth",
                }}
              >
                Our SAP Governance, Risk and Compliance (SAP GRC) service is
                there to ensure your organization meets the security standards
                for your organization whilst giving you visibility of all your
                risks. Managing all these components can be a cumbersome task
                and expensive. At SILOTU Consulting we aim to make the Identity
                Management and GRC journey smooth and cost effective by managing
                its complexities for you, so you don’t have to. Our SAP GRC &
                IdM Support Services include:<br></br>
                <br></br>• User and role provisioning
                <br></br>• Reporting and auditing
                <br></br>• Customizing and maintaining rulesets
                <br></br>• Maintain mitigations, approvers, Fire Fighter
              </p>
            </div>
          </div>
          <div className="grid-itemlong">
            <h3 className="h3infor">
              SAP Marketplace <br></br>Administration Service
            </h3>
            <div className="tilebox">
              <img className="iconI" src={icon9} alt="" />
            </div>
            <div className="textbox">
              <p
                className="infortext"
                style={{
                  maxHeight: "250px",
                  overflowY: "scroll",
                  scrollbarWidth: "thin",
                  scrollbarColor: "linear-gradient(to right, #00b0ff, #00f5ff)",
                  scrollbarRadius: "50px",
                  scrollBehavior: "smooth",
                }}
              >
                Basis handling your marketplace administration? Let us manage
                your users and authorizations. We’ll ensure the correct users
                have the correct authorizations their respective functions. Our
                Marketplace service offering includes:<br></br>
                <br></br>• User and authorization administration
                <br></br>• Register object and developer keys
              </p>
            </div>
          </div>
          <div className="grid-itemlong">
            <h3 className="h3infor">
              SAP Printer <br></br>Administration
            </h3>
            <div className="tilebox">
              <img className="iconI" src={icon10} alt="" />
            </div>
            <div className="textbox">
              <p
                className="infortext"
                style={{
                  maxHeight: "250px",
                  overflowY: "scroll",
                  scrollbarWidth: "thin",
                  scrollbarColor: "linear-gradient(to right, #00b0ff, #00f5ff)",
                  scrollbarRadius: "50px",
                  scrollBehavior: "smooth",
                }}
              >
                Printers giving you a headache? Look no further, we have a team
                that will have support and troubleshooting experience on SAP
                printing issues. We will help your business with:<br></br>
                <br></br>• Setting up printers on SAP, print server and UNIX
                <br></br>• Support and troubleshooting your user's issues with
                fast turnaround times
              </p>
            </div>
          </div>
          <div className="grid-itemlong">
            <h3 className="h3infor">
              SAP Cloud <br></br>Security
            </h3>
            <div className="tilebox">
              <img className="iconI" src={icon11} alt="" />
            </div>
            <div className="textbox">
              <p
                className="infortext"
                style={{
                  maxHeight: "250px",
                  overflowY: "scroll",
                  scrollbarWidth: "thin",
                  scrollbarColor: "linear-gradient(to right, #00b0ff, #00f5ff)",
                  scrollbarRadius: "50px",
                  scrollBehavior: "smooth",
                }}
              >
                Optimization is important in the context of SAP Cloud Platform
                because it can help you ensure that your applications and
                services are performing at their best and delivering the desired
                results in a cost-effective manner. By optimizing your
                applications, you can improve their performance, reduce their
                resource consumption, and increase their scalability and
                reliability. Here are five key benefits of optimization in the
                context of SAP Cloud Platform:<br></br>
                <br></br>• Improved performance: Optimization can help you
                improve the performance of your applications, making them faster
                and more responsive to user requests. This can help you provide
                a better user experience and increase customer satisfaction.
                <br></br>• Reduced resource consumption: Optimization can help
                you reduce the amount of resources (e.g., CPU, memory, network
                bandwidth) that your applications consume, which can help you
                lower your operating costs and improve the efficiency of your
                infrastructure.
                <br></br> • Increased scalability: Optimization can help you
                design your applications to be more scalable, allowing them to
                handle a larger volume of traffic or data without degrading
                performance.<br></br> • Improved reliability: Optimization can
                help you identify and address potential bottlenecks or
                vulnerabilities in your applications, making them more reliable
                and less prone to failures or outages.<br></br>• Increased
                efficiency: Optimization can help you streamline your
                applications and make them more efficient, allowing you to get
                more value out of your resources and infrastructure.
              </p>
            </div>
          </div>
        </div>
        <br></br>
      </div>
    </div>
  );
};

export default Information;
