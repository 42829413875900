import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../../client.js";
import "./AllPost.css";
import BlockContent from "@sanity/block-content-to-react";

export default function AllPosts() {
  const [allPostsData, setAllPosts] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
        title,
        slug,
        body,
        mainImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => {
        console.log(data);
        setAllPosts(data);
      })
      .catch(console.error);
  }, []);

  return (
    <div className="main-container">
      <div className="main-child-container">
        {allPostsData &&
          allPostsData.map((post, index) => (
            <Link to={"/" + post.slug.current} key={post.slug.current}>
              <div className="main-grid-container">
                <div className="container-blogpost-update">
                  <div className="square">
                    <img
                      className="blog-post-image"
                      src={post.mainImage.asset.url}
                      alt=""
                    />
                  </div>
                  <h3 className="allpost-title">{post.title}</h3>
                  <div className="blog-post-description">
                    <BlockContent blocks={post.body} />
                  </div>
                </div>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
}
