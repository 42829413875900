import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../../client.js";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";
import "./OnePost.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return source ? builder.image(source) : null;
}

export default function OnePost() {
  const [postData, setPostData] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
             }
           },
         body,
        "name": author->name,
        "authorImage": author-image,
        publishedAt,
        }`,
        { slug }
      )
      .then((data) => setPostData(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!postData)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <span className="loader"></span>
      </div>
    );

  return (
    <div className="box-container">
      <Navbar />
      <div className="box-content">
        <div
          className="onepost-main-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span>
            <h1 className="onepost-h2">{postData.title}</h1>
          </span>
          <div className="onepost-content">
            <div className="profile-space">
              {postData.authorImage && (
                <img
                  className="profile-image"
                  src={urlFor(postData.authorImage).width(50).url()}
                  alt="Author"
                />
              )}

              <h4 style={{ fontWeight: "normal", fontSize: "1em" }}>
                {postData.name}
              </h4>
            </div>
          </div>
          <div className="one-container-box">
            <div className="blog-content">
              <img
                className="onepost-image"
                src={urlFor(postData.mainImage).width(500).url()}
                alt=""
              />
            </div>

            <div className="onepost-text">
              <BlockContent
                blocks={postData.body}
                projectId={sanityClient.clientConfig.projectId}
                dataset={sanityClient.clientConfig.dataset}
              />
            </div>
          </div>
        </div>
        <br />
        <div>
          <div>
            <div
              style={{
                textAlign: "right",
                color: "#D3D3D3",
                fontWeight: "normal",
              }}
            >
              <h5 style={{ fontWeight: 300, color: "lightgray" }}>
                Published At:{" "}
                {new Date(postData.publishedAt).toLocaleDateString()}
                <br></br>
                {new Date(postData.publishedAt).toLocaleTimeString()}
              </h5>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
