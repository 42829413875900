import React from "react";
import { Link } from "react-router-dom";
import "./ServicesStyles.css";
import img1 from "../../assets/img1.png";
import img2 from "../../assets/img2.png";
import img3 from "../../assets/img3.png";
import img4 from "../../assets/img4.png";

const Services = () => {
  return (
    <div className="services">
      <div className="container">
        <div className="content">
          <div className="boxtile">
            <h1 className="h1">
              <span>What We Do</span>
            </h1>
          </div>
          <div className="service-paragraph-div">
            <p className="service-p-tag">
              We are an official SAP Open Ecosystem Partner with an aim to
              deliver quality specialized SAP Security, Audit, License and GRC
              services at affordable rates. Quality does not need to drain your
              much needed cash, let our team of experts save you time and money
              with rapid analysis, deployment and quality support services.
            </p>
          </div>
          <br></br>

          <div className="row">
            <div className="column1">
              <div className="col">
                <img className="img1" src={img1} alt="" />
              </div>
              <div className="block">
                <h2>Governance, Risk & Compliance</h2>
                <div className="pragrapha-block">
                  <p className="p1">
                    Our SAP Governance, Risk and Compliance (SAP GRC) service is
                    there to ensure your organization meets the security
                    standards for your organization whilst giving you visibility
                    of all your risks. Managing all these components can be a
                    cumbersome task and expensive
                  </p>
                </div>
              </div>
              <div className="btns">
                <Link to="/information">
                  <button className="button1">Read more</button>
                </Link>
              </div>
            </div>
            <div className="column2">
              <div className="col">
                <img className="img2" src={img2} alt="" />
              </div>
              <div className="block">
                <h2 className="black">Audit, Risk & Advisory</h2>
                <div className="pragrapha-block">
                  <p className="black-p">
                    We provide end-to-end audit services to ensure you are
                    compliant and external audit ready. Our team of certified
                    experts will extract, examine and provide recommendations in
                    detailed professional report
                  </p>
                </div>
              </div>
              <div className="btns">
                <Link to="/information">
                  <button className="button1">Read more</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="column3">
              <div className="col">
                <img className="img3" src={img3} alt="" />
              </div>
              <div className="block">
                <h2 className="black">Projects & Support</h2>
                <div className="pragrapha-block">
                  <p className="black-p">
                    Depending on your solution, our support service provides
                    continuous support to your business SAP Enterprise software
                    by protecting your valuable data from unauthorized access.
                    We are committed to providing your organization an
                    efficient, cost-effective, scalable authorizations strategy.
                  </p>
                </div>
              </div>
              <div className="btns">
                <Link to="/information">
                  <button className="button1">Read more</button>
                </Link>
              </div>
            </div>
            <div className="column4">
              <div className="col">
                <img className="img4" src={img4} alt="" />
              </div>
              <div className="block">
                <h2>Identity & Access Control</h2>
                <div className="pragrapha-block">
                  <p className="p2">
                    Protecting your organizations assets against unauthorized
                    users is a vital component to internal controls.Well ensure
                    only the right user is accessing the right data at the right
                    time. Your data is important to you, and so we value what is
                    important to you.
                  </p>
                </div>
              </div>
              <div className="btns">
                <Link to="/information">
                  <button className="button1">Read more</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
    </div>
  );
};

export default Services;
