import React from "react";
import "./DataStyles.css";
import icon1 from "../../assets/icon1.png";
import icon2 from "../../assets/icon2.png";
import icon3 from "../../assets/icon3.png";
import icon4 from "../../assets/icon4.png";
import icon5 from "../../assets/icon5.png";
import icon6 from "../../assets/icon6.png";
import icon7 from "../../assets/icon7.png";
import icon8 from "../../assets/icon8.png";
import icon9 from "../../assets/icon9.png";
import icon10 from "../../assets/icon10.png";
import icon11 from "../../assets/icon11.png";
import { Link } from "react-router-dom";

const Data = () => {
  return (
    <div className="data">
      <div className="contaniner">
        <div className="content">
          <div className="boxtile">
            <h1 className="h1">
              <span>Our Services</span>
            </h1>
          </div>
          <p className="p-slt">
            SILOTU Consulting is offering below services at cost effective rates
            to address the protection of data and <br></br>information on your
            organizations SAP Enterprise Software.
          </p>
          <br></br>
          <br></br>
          <h2 className="h2e">Executive Summary</h2>
          <p className="parhead">
            Below is an executive summary of our service offerings:
          </p>

          <br></br>
          <div className="data-grid-container">
            <div className="grid-item">
              <h3>
                SAP HANA <br></br>Database Security
              </h3>
              <div className="tilebox">
                <img className="iconI" src={icon1} alt="" />
              </div>
            </div>
            <div className="grid-item">
              <h3>
                SAP ITGC <br></br>Auditing Services
              </h3>
              <div className="tilebox">
                <img className="iconI" src={icon2} alt="" />
              </div>
            </div>
            <div className="grid-item">
              <h3>
                SAP <br></br>Optimization Services
              </h3>
              <div className="tilebox">
                <img className="iconI" src={icon3} alt="" />
              </div>
            </div>
            <div className="grid-item">
              <h3>
                SAP <br></br>Licensing Services
              </h3>
              <div className="tilebox">
                <img className="iconI" src={icon4} alt="" />
              </div>
            </div>
            <div className="grid-item">
              <h3>
                SAP Projects &<br></br> Implementations
              </h3>
              <div className="tilebox">
                <img className="iconI" src={icon5} alt="" />
              </div>
            </div>
            <div className="grid-item">
              <h3>
                SAP PO <br></br>Sercurity Services
              </h3>
              <div className="tilebox">
                <img className="iconI" src={icon6} alt="" />
              </div>
            </div>
            <div className="grid-item">
              <h3>
                SAP Support <br></br>Services
              </h3>
              <div className="tilebox">
                <img className="iconI" src={icon7} alt="" />
              </div>
            </div>
            <div className="grid-item">
              <h3>
                SAP GRC & IDM <br></br> Support Services
              </h3>
              <div className="tilebox">
                <img className="iconI" src={icon8} alt="" />
              </div>
            </div>
            <div className="grid-item">
              <h3>
                SAP Marketplace <br></br>Administration Service
              </h3>
              <div className="tilebox">
                <img className="iconI" src={icon9} alt="" />
              </div>
            </div>
            <div className="grid-item">
              <h3>
                SAP Printer<br></br> Administration
              </h3>
              <div className="tilebox">
                <img className="iconI" src={icon10} alt="" />
              </div>
            </div>
            <div className="grid-item">
              <h3>
                SAP Cloud<br></br> Optimization
              </h3>
              <div className="tilebox">
                <img className="icon11" src={icon11} alt="" />
              </div>
            </div>
          </div>
          <br></br>
          <div className="iconbtn">
            <Link to="/information">
              <button className="button1">Read more</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Data;
