import React from "react";
import Navbar from "../components/navbar/Navbar";
import BlogPost from "../components/BlogPost/BlogPost";
import Footer from "../components/footer/Footer";

const BlogPostPage = () => {
  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <BlogPost />
        <Footer />
      </div>
    </>
  );
};

export default BlogPostPage;
