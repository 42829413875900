import React from "react";
import "./PartnersStyles.css";
import logo1 from "../../assets/logo1.png";
import logo2 from "../../assets/logo2.png";
import logo3 from "../../assets/logo3.png";
import logo4 from "../../assets/logo4.png";
import logo5 from "../../assets/logo5.png";
import logo6 from "../../assets/logo6.png";

const Partners = () => {
  return (
    <div className="partners">
      <div className="container">
        <div className="content">
          <div className="boxtile">
            <h1 className="h1">
              <span>Our Customers </span>
            </h1>
          </div>
          <div className="p-box">
            <div className="innerbox">
              <div className="imgsizebox">
                <a
                  href="http://www.fruitspot.co.za/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="businesslogoF" src={logo1} alt="" />
                </a>
              </div>
            </div>
            <div className="innerbox">
              <div className="imgsizeboxM-C">
                <a
                  href="https://www.massmart.co.za/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="businesslogoS" src={logo2} alt="" />
                </a>
              </div>
            </div>
            <div className="innerbox">
              <div className="imgsizeboxM-C">
                <a
                  href="https://www.cambridgefood.co.za/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="businesslogoC" src={logo3} alt="" />
                </a>
              </div>
            </div>
            <div className="innerbox">
              <div className="imgsizebox">
                <a
                  href="https://www.makro.co.za/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="businesslogoM" src={logo5} alt="" />
                </a>
              </div>
            </div>
            <div className="innerbox">
              <div className="imgsizebox">
                <a
                  href="https://www.builders.co.za/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="businesslogoB" src={logo6} alt="" />
                </a>
              </div>
            </div>
            <div className="innerbox">
              <div className="imgsizebox">
                <a
                  href="https://www.game.co.za/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="businesslogoG" src={logo4} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
