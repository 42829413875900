import React from "react";
import Information from "../components/Information/Information";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";

const InformationPage = () => {
  return (
    <>
      <Navbar />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Information />
      <Footer />
    </>
  );
};

export default InformationPage;
