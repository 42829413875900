import React from "react";

import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import Services from "../components/Services/Services";

const ServicesPage = () => {
  return (
    <>
      <Navbar />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Services />
      <Footer />
    </>
  );
};

export default ServicesPage;
