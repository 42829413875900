import React, { useState } from "react";
import { Link as NavLink } from "react-router-dom";
import "./FooterStyles.css";
import logo from "../../assets/logoa.png";
import { FiFacebook, FiPhoneCall, FiLinkedin } from "react-icons/fi";
import { TbSend } from "react-icons/tb";
import { RiWhatsappLine } from "react-icons/ri";
import { SiTwitter } from "react-icons/si";
import { MdOutlineLocationOn, MdOutlineMail } from "react-icons/md";
import { Link } from "react-scroll";

const Footer = () => {
  const [email, setEmail] = useState("");

  const handleClick = () => {
    window.location.href = `mailto:directors@silotuconsulting.co.za?subject=Contact&body=${email}`;
  };

  return (
    <div className="footer">
      <div className="container">
        <div className="top">
          <Link
            activeClass="active"
            to="top"
            spy={true}
            smooth={true}
            duration={1500}
          >
            <img src={logo} alt="" />
          </Link>

          <div className="col-container">
            <div className="col">
              <h3 className="footerh3">Navigation</h3>
              <NavLink to="/">
                <p className="footertext">Home</p>
              </NavLink>
              <NavLink to="/about">
                <p className="footertext">About</p>
              </NavLink>
              <NavLink to="/services">
                <p className="footertext">Services</p>
              </NavLink>
              <NavLink to="/partners">
                <p className="footertext">Partners</p>
              </NavLink>
              <NavLink to="/blogpost">
                <p className="footertext">Blog</p>
              </NavLink>
            </div>
            <div className="col">
              <h3 className="footerh3">Our Services</h3>
              <p className="footertext">Audit, Risk & Advisory</p>
              <p className="footertext">Governance, Risk & Compliance</p>
              <p className="footertext">Identity & Access Controls</p>
              <p className="footertext">Project & Support</p>
            </div>
            <div className="col">
              <h3 className="footerh3">Information</h3>
              <p className="footertext">
                <a href="mailto:directors@silotuconsulting.co.za">
                  <span className="pop-email">
                    <MdOutlineMail />
                    directors@silotuconsulting.co.za
                  </span>
                </a>
              </p>
              <p className="footertext">
                <a
                  href="https://www.google.com/maps/place/Rugby+St,+Weltevredenpark,+Roodepoort/@-26.1332584,27.9343005,17z/data=!3m1!4b1!4m5!3m4!1s0x1e95754d6564d6fb:0x3c714560c87d32b8!8m2!3d-26.1332584!4d27.9364892"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="pop-map">
                    <MdOutlineLocationOn />
                    181 Derby Dr, Northriding, Randburg, 2169
                  </span>
                </a>
              </p>
              <p className="footertext">+27 (0)11 662 2893</p>
            </div>
            <div className="col">
              <h3 className="footerh3">Legal</h3>
              <p className="footertext">Terms & Conditions</p>
              <p className="footertext">Privacy Policy</p>
              <p className="footertext">Terms of use</p>
            </div>
            <form>
              <h3>Get in touch</h3>
              <div className="input-container">
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TbSend className="mail-icon" onClick={handleClick} />
              </div>
              <div className="social-group">
                <a
                  href="https://www.linkedin.com/company/silotu-consulting-pty-ltd"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FiLinkedin className="social-icon" />
                </a>
                <a
                  href="https://www.facebook.com/people/Silotu-Consulting-PTY-LTD/100063847820636/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FiFacebook className="social-icon" />
                </a>
                <a
                  href="https://twitter.com/SilotuL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SiTwitter className="social-icon" />
                </a>
                <a
                  href="tel:+27 (0)11 662 2893"
                  onClick="ga('send', 'event', { eventCategory: 'Contact', eventAction: 'Call', eventLabel: 'Mobile Button'});"
                >
                  <FiPhoneCall className="social-icon" />
                </a>
                <a
                  href="https://api.whatsapp.com/message/KRBS4RTYFB63P1?autoload=1&app_absent=0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <RiWhatsappLine className="social-icon" />
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
      <br></br>
      <div className="copy-right">
        SILOTU Consulting Copyright © 2023. All rights reserved.
      </div>
    </div>
  );
};

export default Footer;
